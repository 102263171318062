(function () {
  'use strict';

  class EditSchoolsCtrl {
    constructor($mdDialog, EditSchools, Schools, Media) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Schools = Schools;
      vm.Media = Media;
      vm.school = EditSchools.getObj();
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    uploadMedia() {
      const vm = this;
      if (vm.media && vm.media.file) {
        vm.Media.mediaCreate(vm.media.file)
          .then((data)=>{
            vm.school.logo = data.file.url;
            vm.schoolEdit();
          });
      } else {
        vm.schoolEdit();
      }
    }
    schoolEdit() {
      let vm = this;
      vm.Schools.schoolEdit(vm.school)
        .then((data)=> {
          vm.hide(data);
        });
    }
    submit(form) {
      let vm = this;
      if (form.$valid) {
        vm.schoolEdit();
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:EditSchoolsCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('EditSchoolsCtrl', EditSchoolsCtrl);
}());
